import React from 'react';
// Translations
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const Error = ({ statusCode }) => (
	<div className=":card :mt20">
		<div className=":flex:center :pt20 :pb20">
			{statusCode
				? `An error ${statusCode} occurred on server`
				: 'An error occurred on client'}
		</div>
	</div>
);

export const getServerSideProps = async ({ res, err, locale }) => {
	let statusCode = null;
	if (res) {
		({ statusCode } = res);
	} else if (err) {
		({ statusCode } = err);
	}
	return {
		props: {
			...await serverSideTranslations(locale, ['common']),
			statusCode,
		},
	};
};

Error.defaultProps = {
	statusCode: null,
};

export default Error;
